<template>
  <div>
    <!-- MAIN LOADER -->
    <template v-if="loader && !campaign">
        <Loader
          :size="'big'"
        />
    </template> <!-- END MAIN LOADER -->
    <template v-else-if="currentLine">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center mb-5">
        <div class="col-lg-7">
          <h1 class="highlighted-color1 font-size-large wrap-text">
            <span>{{ currentLine.name }}</span>
          </h1>
        </div>
        <div class="d-flex">
          <div id="edit-line-wrap">
            <router-link
              :to="{ name: 'lines.edit', params: { cId: campaign.id, id: currentLine.id } }"
              class="btn btn-sm btn-primary me-2"
              :class="{
                disabled: isCampaignPaused
              }"
              v-if="campaign.status !== 'FINISHED'"
            >
              Editar estratégia
            </router-link>
            <b-tooltip
              target="edit-line-wrap"
              v-if="isCampaignPaused"
            >
              Não é permitido editar uma estratégia de uma campanha pausada.
            </b-tooltip>
          </div>
          <template v-if="campaign.status !== 'FINISHED'">
            <template v-if="currentLine.status === 'ACTIVE'">
              <Button
                id="pause-line-button"
                class="btn-sm btn-color1 me-2"
                @click="openPauseCampaignLineModal"
                :softDisabled="isCampaignPaused"
              >
                Pausar
              </Button>
              <b-tooltip
                target="pause-line-button"
                v-if="isCampaignPaused"
              >
                Não é permitido pausar uma estratégia de uma campanha pausada.
              </b-tooltip>
              <PauseCampaignLineModal
                :campaign="campaign"
                :line="currentLine"
                @line-paused="getCampaign"
              />
            </template>
            <template v-else-if="currentLine.status === 'PAUSED'">
              <Button
                id="activate-line-button"
                class="btn-sm btn-color1 me-2"
                @click="openActivateCampaignLineModal"
                :softDisabled="isCampaignPaused"
              >
                Ativar
              </Button>
              <b-tooltip
                target="activate-line-button"
                v-if="isCampaignPaused"
              >
                Não é permitido ativar uma estratégia de uma campanha pausada.
              </b-tooltip>
              <ActivateCampaignLineModal
                :campaign="campaign"
                :line="currentLine"
                @line-activated="getCampaign"
              />
            </template>
          </template>
          <router-link :to="{ name: 'packages.show', params: { cId: campaign.id, id: currentPackage.id } }" class="btn btn-sm btn-primary">Voltar</router-link>
        </div>
      </div> <!-- END HEADER -->
      <!-- MESSAGES -->
      <div
        class="mb-4"
        v-if="currentLine.status === 'ERROR' || currentLine.status === 'PAUSED'"
      >
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentLine.status === 'ERROR'"
        >
          <p
            class="mb-0"
          >
            Ops, houve um erro na criação da estratégia. Clique aqui para tentar de novo.
            <span>
              <RetryCampaignLineCreationOnPlatformButton
                :lineId="currentLine.id"
                @retry-successful="getCampaign"
                @retry-error="handleRetryError"
                color="white"
              />
            </span>
          </p>
        </div>
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentLine.status === 'PAUSED'"
        >
          <p
            class="mb-0"
          >
            Esta estratégia está pausada
          </p>
        </div>
      </div> <!-- END MESSAGES -->
      <!-- BIG NUMBERS -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium">
          <span>Desempenho</span>
        </h2>
        <!-- FILTER -->
        <div class="mb-4">
          <div class="d-flex text-center align-items-center justify-content-center justify-content-lg-end font-size-small default-form">
            Filtrar de
            <DateTimeInput
              v-model.trim="filters.summaryStart"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
            até
            <DateTimeInput
              v-model.trim="filters.summaryEnd"
              format="dd/MM/yyyy"
              type="date"
              theme="basic"
              class="mx-2 form-control-sm-wrapper"
            />
          </div>
        </div> <!-- END FILTER -->
        <div v-if="summaryLoader" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <CampaignSummary
          :data="summary"
          view="lines"
          v-else-if="summary"
        />
      </div> <!-- END BIG NUMBERS -->
      <!-- TABS -->
      <b-tabs content-class="mt-4" nav-class="default-tabs">
        <!-- OVERVIEW TAB -->
        <b-tab title="Dados gerais">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Dados gerais</span>
            </h2>
            <div class="card">
              <div class="card-body">
                <p>
                  <strong>Nome:</strong> {{ currentLine.name }}<br/>
                  <strong>Início:</strong> {{ currentPackage.start_date | formatDate }}<br/>
                  <strong>Término:</strong> {{ currentPackage.end_date | formatDate }}<br/>
                  <strong>Status:</strong> {{ currentLine.status | formatCampaignStatus }}<br/>
                  <strong>Gasto até o momento:</strong> {{ currentLine.spending / 100 | formatMoney }}<br/>
                  <strong>Impressões totais:</strong> {{ currentLine.impressions | formatNumber }}<br/>
                  <strong>Cliques totais:</strong> {{ currentLine.clicks | formatNumber }}<br/>
                  <strong>Conversões totais:</strong> {{ currentLine.conversions | formatNumber }}<br/>
                  <strong>CTR:</strong> {{ currentLine.ctr | formatNumber }}%<br/>
                  <strong>CPM:</strong> {{ currentLine.cpm / 100 | formatMoney }}<br/>
                  <strong>CPA:</strong> {{ currentLine.cpa / 100 | formatMoney }}
                </p>
              </div>
            </div>
          </div>
        </b-tab> <!-- END OVERVIEW TAB -->
        <!-- CREATIVES TAB -->
        <b-tab title="Criativos">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Criativos</span>
            </h2>
            <CreativeListTable
              :creatives="currentLine.creatives"
            />
          </div>
        </b-tab> <!-- END CREATIVES TAB -->
        <!-- TARGETING TAB -->
        <b-tab title="Targeting">
          <div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Audiências</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="currentLine.audiences.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(audience, index) in currentLine.audiences"
                      :key="index"
                    >
                      {{ audience.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem audiências selecionadas</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Localizações</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <h2 class="highlighted-color1 font-size-regular mb-3">
                  <span>Estados</span>
                </h2>
                <template v-if="currentLine.states.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(state, index) in currentLine.states"
                      :key="index"
                    >
                      {{ state.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem estados selecionados</p>
                </template>

                <h2 class="highlighted-color1 font-size-regular mb-3 mt-4">
                  <span>Cidades</span>
                </h2>
                <template v-if="currentLine.cities.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(city, index) in currentLine.cities"
                      :key="index"
                    >
                      {{ city.name }} ({{ city.state_short_name }})
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem cidades selecionadas</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Browsers</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="currentLine.browser.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(browser, index) in currentLine.browser"
                      :key="index"
                    >
                      {{ browser }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem browsers selecionados</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Audiências Customizadas</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="currentLine.custom_audiences.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(audience, index) in currentLine.custom_audiences"
                      :key="index"
                    >
                      {{ audience.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem audiências customizadas selecionadas</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Blocklist</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="currentLine.blacklist.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(item, index) in currentLine.blacklist"
                      :key="index"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem blocklist selecionada</p>
                </template>
              </div>
            </div>
            <h2 class="highlighted-color1 font-size-medium mb-4">
              <span>Wishlist</span>
            </h2>
            <div class="card mb-5">
              <div class="card-body">
                <template v-if="currentLine.wishlist.length > 0">
                  <ul class="mb-0">
                    <li
                      v-for="(item, index) in currentLine.wishlist"
                      :key="index"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>Sem wishlist selecionada</p>
                </template>
              </div>
            </div>
          </div>
        </b-tab> <!-- END TARGETING -->
      </b-tabs> <!-- END TABS -->
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

import ActivateCampaignLineModal from '@/components/campaigns/ActivateCampaignLineModal'
import Button from '@/components/common/Button/Button'
import CampaignSummary from '@/components/campaigns/CampaignSummary'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignLineModal from '@/components/campaigns/PauseCampaignLineModal'
import RetryCampaignLineCreationOnPlatformButton from '@/components/campaigns/RetryCampaignLineCreationOnPlatformButton'

export default {
  components: {
    ActivateCampaignLineModal,
    Button,
    CampaignSummary,
    CreativeListTable,
    DateTimeInput,
    Loader,
    PauseCampaignLineModal,
    RetryCampaignLineCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      filters: {
        summaryStart: '',
        summaryEnd: ''
      },
      loader: false,
      summary: null,
      summaryLoader: true
    }
  },
  metaInfo () {
    return {
      title: this.currentLine ? this.currentLine.name : ''
    }
  },
  created () {
    this.filters.summaryStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(90, 'days').format()
    this.filters.summaryEnd = moment({ hour: 0, minute: 0, seconds: 0 }).format()
    if (this.storeCampaign) {
      this.campaign = this.storeCampaign
      this.getCampaignSummary()
    } else {
      this.getCampaign()
    }
  },
  watch: {
    'filters.summaryStart': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    },
    'filters.summaryEnd': function (newValue, oldValue) {
      if (!oldValue) return
      const newV = moment(newValue)
      const oldV = moment(oldValue)
      if (!newV.isSame(oldV)) {
        this.getCampaignSummary()
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      storeCampaign: state => state.currentCampaign
    }),
    /**
     * Current line
     */
    currentLine () {
      if (!this.currentPackage) return null
      return this.currentPackage.lines.find(item => {
        return item.id === this.$route.params.id
      })
    },
    /**
     * Current package
     */
    currentPackage () {
      if (!this.campaign) return null
      return this.campaign.packages.find(item => {
        return item.lines.find(line => {
          return line.id === this.$route.params.id
        })
      })
    },
    /**
     * Is campaign paused
     */
    isCampaignPaused () {
      if (!this.campaign) return false
      return this.campaign.status === 'PAUSED'
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get campaign
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.cId)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
        this.getCampaignSummary()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Get campaign summary
     */
    async getCampaignSummary () {
      this.summaryLoader = true
      try {
        const summary = await CampaignsService.getCampaignsSummary({
          lineIds: [this.currentLine.id],
          startDate: moment(this.filters.summaryStart).format('YYYY-MM-DD'),
          endDate: moment(this.filters.summaryEnd).format('YYYY-MM-DD')
        })
        this.summary = summary
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.summaryLoader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Open activate campaign line modal
     */
    openActivateCampaignLineModal () {
      this.$bvModal.show('activate-campaign-line-modal')
    },
    /**
     * Open pause campaign line modal
     */
    openPauseCampaignLineModal () {
      this.$bvModal.show('pause-campaign-line-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
